import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Image from "../components/image"

const DevelopmentConsultancy = () => (
  <Layout>
    <SEO title="Development Consultancy" />

    <div className="page-banner">
      <div className="container">
        <h1>
          Development Consultancy
        </h1>
      </div>
    </div>

    <div className="banner-skew">
      <div className="skew-left"></div>
    </div>

    <div className="page-content">
    <div className="container">
      <h2>Development Consultancy</h2>
      <div>
        <p>Aim Projects is the property development consultancy arm of GradeA Group. Our goal is to turn an idea into a vision, then into a development strategy, and then set-up the implementation plan.</p>

        <p>Curving its own niche in the property market, Aim Projects focuses on supporting clients unlock the potentials of a development site or an asset.</p>

        <p>Aim Projects offers front end development management services, particularly:</p>

        <h3>Development Analysis</h3>
        <p>Provide strategic advisory by co-creating a vision with the owner; Lead the design team in creating the master plan; Set-up financial model and provide feasibility analysis; Create Development Strategy</p>

        <h3>Acquisition, Sale and Divestment</h3>
        <p>Due diligence; Determine site feasibility and potential value; Negotiate with owners, purchasers or investors; Facilitate sales campaign; Prepare and negotiate RFP</p>

        <h3>Project Set-up and Project Review</h3>
        <p>Create an implementation plan; Set up project team; Pre- pare project brief & programme; Provide peer review.</p>
      </div>
    </div>
    </div>

  </Layout>
)

export default DevelopmentConsultancy
